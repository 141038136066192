var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-title", {
        attrs: { title: _vm.title, passedActions: _vm.primaryTitleBarActions },
      }),
      _c(
        "v-container",
        { staticClass: "list-card fill-height pa-2", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("ProductSelectionCard", {
                            attrs: {
                              title: "Select items to attach to image",
                              preSelectedItems: _vm.modalItems,
                              includeGroups: true,
                              size: "75vh",
                            },
                            on: {
                              close: () => {
                                this.productSelectionModal = false
                              },
                              save: _vm.handleNewSelections,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "8" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-2" },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  color: "#93BD20",
                                                  items: _vm.categoryItems,
                                                  label: "Category",
                                                  clearable: "",
                                                  outlined: "",
                                                  "prepend-icon": "folder",
                                                },
                                                model: {
                                                  value: _vm.category,
                                                  callback: function ($$v) {
                                                    _vm.category = $$v
                                                  },
                                                  expression: "category",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticStyle: { "padding-bottom": "0px" },
                                    },
                                    [_c("h2", [_vm._v("Alcohol Flag")])]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "0px",
                                                  },
                                                  attrs: {
                                                    hint: "test",
                                                    "hide-details": "",
                                                    dense: "",
                                                    row: "",
                                                  },
                                                  model: {
                                                    value: _vm.alcohol,
                                                    callback: function ($$v) {
                                                      _vm.alcohol = $$v
                                                    },
                                                    expression: "alcohol",
                                                  },
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      color: "#93BD20",
                                                      label: "On",
                                                      value: "true",
                                                    },
                                                  }),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      color: "#93BD20",
                                                      label: "Off",
                                                      value: "off",
                                                    },
                                                  }),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      color: "#93BD20",
                                                      label: "No Change",
                                                      value: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticStyle: { "padding-bottom": "0px" },
                                    },
                                    [_c("h2", [_vm._v("Taxes")])]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "4" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "State %",
                                                  color: "#93BD20",
                                                  clearable: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { md: "4" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Local %",
                                                  color: "#93BD20",
                                                  clearable: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { md: "4" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  color: "#93BD20",
                                                  label: "Other %",
                                                  clearable: "",
                                                  type: "number",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }