var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "upsell-selection-card" }, [
    _c(
      "div",
      {
        directives: [{ name: "bar", rawName: "v-bar" }],
        staticClass: "scroll-container",
        style: _vm.scrollContainerStyle,
      },
      [
        _c(
          "v-card-text",
          { staticClass: "pa-0" },
          [
            _c("v-data-table", {
              ref: "sortableList",
              staticClass: "el2",
              attrs: {
                items: _vm.items,
                headers: _vm.headers,
                "disable-pagination": "",
                "hide-default-header": "",
                "hide-default-footer": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function (props) {
                    return [
                      _c(
                        "tr",
                        {
                          staticClass: "upsell-selection-row",
                          attrs: { active: props.selected },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.toggleEither(props.item)
                            },
                          },
                        },
                        [
                          props.item.group
                            ? _c(
                                "td",
                                [
                                  _c("v-row", { staticClass: "pt-1" }, [
                                    _c("div", { staticClass: "group-icon" }),
                                    _c("div", { staticClass: "group-title" }, [
                                      _vm._v(_vm._s(props.item.title)),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _c("td", [
                                _c("div", { staticClass: "item-title" }, [
                                  _vm._v(_vm._s(props.item.title)),
                                ]),
                              ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.selectedItems,
                callback: function ($$v) {
                  _vm.selectedItems = $$v
                },
                expression: "selectedItems",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }