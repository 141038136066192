<template>
  <div>
    <context-title :title="title" :passedActions="primaryTitleBarActions"></context-title>
    <v-container fluid class="list-card fill-height pa-2">
      <v-row>
        <v-col sm="12">
          <v-row >
            <v-col sm="4">
              <ProductSelectionCard
                title="Select items to attach to image"
                :preSelectedItems="modalItems"
                :includeGroups="true"
                size="75vh"
                @close="() =>  {this.productSelectionModal = false}"
                @save="handleNewSelections"
              />
            </v-col>
            <v-col sm="8">
              <v-row class="pa-2">
                <v-card>
                  <v-card-text>
                    <v-row >
                      <v-col md="12">
                        <v-select
                          v-model="category"
                          color="#93BD20"
                          :items="categoryItems"
                          label="Category"
                          clearable
                          outlined
                          prepend-icon="folder"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card style="margin-top: 10px;">
                  <v-card-title style="padding-bottom: 0px;">
                    <h2>Alcohol Flag</h2>
                  </v-card-title>
                  <v-card-text>
                    <v-row >
                      <v-col md="12">
                        <v-radio-group
                          style="margin-top: 0px;"
                          hint="test"
                          hide-details
                          dense
                          v-model="alcohol"
                          row
                        >
                          <v-radio color="#93BD20" label="On" value="true"></v-radio>
                          <v-radio color="#93BD20" label="Off" value="off"></v-radio>
                          <v-radio color="#93BD20" label="No Change" value></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card style="margin-top: 10px;">
                  <v-card-title style="padding-bottom: 0px;">
                    <h2>Taxes</h2>
                  </v-card-title>
                  <v-card-text>
                    <v-row >
                      <v-col md="4">
                        <v-text-field
                          label="State %"
                          color="#93BD20"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col md="4">
                        <v-text-field
                          label="Local %"
                          color="#93BD20"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col md="4">
                        <v-text-field
                          color="#93BD20"
                          label="Other %"
                          clearable
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ContextTitle from "components/context_title.vue";
import ActionButton from "components/action_button";
import TextButton from "components/text_button.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import ProductSelectionCard from "components/product_selection_card.vue";
import clone from "clone";
import AccessMixin from "mixins/access_helper.js";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "BulkEdit",
    mixins: [AccessMixin],
    data() {
        return {
            productSelections: [],
            modalItems: [],
            category: ""
        };
    },
    props: {},
    components: {
        ContextTitle,
        ActionButton,
        TextButton,
        AlertDialog,
        EditingModal,
        ProductSelectionCard
    },
    computed: {
        title() {
            return "Bulk Edit";
        },
        primaryTitleBarActions() {
            if (!this.dirty || !this.valid) return [];

            const items = [];

            const previous = {
                type: "proceed-green",
                display: "Save & View Previous",
                run: () => {
                    this.save().then(() => this.gotoPreviousItem());
                }
            };
            const saveClose = {
                type: "proceed-green",
                display: "Save & Close",
                run: () => {
                    this.save().then(() => this.leave());
                }
            };

            const saveNext = {
                type: "next-green",
                display: "Save & View Next",
                run: () => {
                    this.save().then(() => {
                        this.gotoNextItem();
                    });
                }
            };

            items.push(saveClose);

            if (this.hasNextItem(this.productId)) {
                items.push(saveNext);
            }

            return items;
        },
        categoryItems() {
            return this.productCategories.map(el => el.inventoryTitleDescription);
        },
        dirty() {
            return false;
        },
        ...mapGetters(["store", "productCategories", "products", "productsMap"])
    },
    methods: {
        handleNewSelections(e) {}
    },
    watch: {}
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";
</style>
